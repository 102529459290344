<template>
  <v-card class="elevation-6 pa-3 form-card">
    <v-card-text>
      <v-form v-model="valid" ref="form">
        <v-flex class="d-flex justify-center" id="pick-avatar">
          <v-avatar size="200">
            <v-img
              width="200px"
              height="200px"
              max-width="200px"
              max-height="200px"
              :src="people.picture"
              lazy-src="static/avatar.png"
              id="pick-avatar"
              @click="_onSelectButtonClick"
            />
          </v-avatar>

          <input
            style="display: block"
            ref="imageInput"
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            name="image"
            @change="onChange"
          />
        </v-flex>

        <v-text-field
          label="Name"
          v-model="people.name"
          :rules="[rules.required]"
        ></v-text-field>

        <v-text-field
          append-icon="email"
          name="email"
          placeholder="E-mail"
          type="email"
          v-model="people.email"
          :rules="[rules.required, rules.email]"
        ></v-text-field>

        <v-flex xs12 d-flex v-if="_isSuperUser">
          <v-select
            v-model="people.role"
            :items="['admin', 'user']"
            label="Role"
          ></v-select>
        </v-flex>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="mt-5 btn_gradient"
        @click="_updateOrCreateCompany"
        :loading="loading"
      >
        Salvar
      </v-btn>
    </v-card-actions>
    <CropImageDialog
      title="Crop Image"
      :show="isCropImageDialogVisible"
      :image="imgSrc"
      :loading="loading"
      :dismiss-action="_closeCropDialog"
      :on-image-cropped="_onImageCropped"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CropImageDialog from '@/components/dialogs/CropImageDialog';
import constants from '@/utils/constants';
import firebase from 'firebase/app';
import 'firebase/storage';

export default {
  components: {
    CropImageDialog
  },
  data() {
    return {
      valid: true,
      isCropImageDialogVisible: false,
      people: { role: constants.ROLE_USER },
      imgSrc: null,
      bannerUrl: null,
      videoUrl: null,
      loading: false,
      rules: {
        required: (value) => !!value || 'Required.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid E-mail';
        }
      }
    };
  },
  computed: {
    ...mapGetters(['getUser']),
    _isSuperUser() {
      return this.getUser.role === constants.ROLE_SUPER_USER;
    }
  },
  methods: {
    ...mapActions({
      _getPeople: 'getPeopleById',
      _updatePeople: 'updatePeople',
      _createPeople: 'addPeople'
    }),

    async _updateOrCreateCompany() {
      this.loading = true;
      if (this.$route.params.peopleId) {
        await this._updatePeople(this.people);
      } else {
        this.people.company = this.$route.params.id;
        this.people.password = (Math.random() + 1).toString(36).substring(2);
        await this._createPeople(this.people);
      }
      this.loading = false;
      await this.$router.back();
    },

    onChange(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.isCropImageDialogVisible = true;
          this.imgSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    _onSelectButtonClick() {
      this.$refs.imageInput.click();
    },
    async _onImageCropped(file) {
      this.loading = true;
      try {
        const snapshot = await firebase
          .storage()
          .ref(`people/${new Date().toISOString()}`)
          .put(file);
        this.people.picture = await snapshot.ref.getDownloadURL();
        this.isCropImageDialogVisible = false;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    _closeCropDialog() {
      this.isCropImageDialogVisible = false;
    }
  },
  async mounted() {
    const peopleId = this.$route.params.peopleId;
    if (peopleId) {
      this.people = await this._getPeople(peopleId);
    }
  }
};
</script>

<style>
input[type='file'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.form-card {
  max-width: 450px;
  margin: 0 auto;
  box-shadow: none;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.theme--light.v-sheet {
  background-color: rgb(255 255 255 / 50%);
  border-radius: 30px;
  box-shadow: none !important;
}
.theme--light.v-sheet .white {
  background-color: transparent !important;
}
.v-toolbar__title h4 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  color: #fff;
  margin-top: 5px;
  margin-left: 6px;
}
</style>
